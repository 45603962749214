<template>
  <div class="home">
    <ComicComponent :nr="$route.params.nr" :comic="$route.params.comic" :key="$route.params.nr"/>
    <p>
      <input type="text" v-model="newnr" />
    </p>
  </div>
</template>

<script>
import ComicComponent from '../components/ComicComponent.vue'
import {comicCookies} from '../cookies.js'

export default {
  name: 'ComicstripView',
  components: {
    ComicComponent
  },
  data() {
    return {
      intNr: this.$route.params.nr
    }
  },
  methods: {
    created: function() {
      comicCookies.setCookie(this.$route.params.comic,this.$route.params.nr, 365);
      this.intNr = this.$route.params.nr
      //console.log(comicCookies.getCookie(this.$route.params.comic));
    }
  },
  computed: {
    newnr: {
      get() {
        return this.intNr;
      },
      set(intNr) {
        this.$emit('update:modelValue',intNr);
        comicCookies.setCookie(this.$route.params.comic,intNr, 365);
        this.$router.replace({ path: '/c/' + this.$route.params.comic + '/' + intNr });
      }
    }
  },
  watch: {
    '$route': 'created'
  }
}
</script>
