<template>
  <div class="home">
    <ul>
      <li v-for="(item, index) in this.comics" :key="index">
        <router-link :to="'/c/' + index">{{index}}</router-link> |
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
       comics: {},
    }
  },
  methods: {
    getComics() {
      fetch('/api/index.php?getComics')
        .then(response => response.json())
        .then(data => (this.comics = data));
    },
  },
  created: function() {
    this.getComics();
    console.log(this.comics);
  }
}
</script>
