<template>
  <div class="comicstrip">
    <p><router-link :to="'/c/' + comic + '/' + getPrev()">Previous</router-link> - <router-link :to="'/c/' + comic + '/' + getNext()">Next</router-link></p>
    <p>
        <img :src="'/'+this.getImg()"  style="width: 100%;" />
    </p>
    <p><router-link :to="'/c/' + comic + '/' + getPrev()">Previous</router-link> - <router-link :to="'/c/' + comic + '/' + getNext()">Next</router-link></p>
    <p><router-link :to="'/c/' + comic">Top of the comic</router-link></p>
  </div>
</template>

<script>
export default {
  name: 'ComicComponent',
  props: {
    nr: String,
    comic: String
  },
  data() {
    return {
       json: {},
       newNumber: 1,
    }
  },
  methods: {
    goToComic() {
      console.log(this.newNumber);
    },
    getJson() {
      fetch('/api/index.php?comic='+ this.comic + '&nr=' +  this.nr + '&format=json')
        .then(response => response.json())
        .then(data => (this.json = data));
    },
    getImg() {
      return this.json.path;
    },
    getNext() {
      return this.json.next;
    },
    getPrev() {
      return this.json.prev;
    },
    getHeight() {
      return this.json.height;
    },
    getWidth() {
      return this.json.width;
    },
    touchstart(touchEvent) {
        if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
          return;
        }
        const posXStart = touchEvent.changedTouches[0].clientX;
        addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
    },
    touchEnd (touchEvent, posXStart) {
        if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
          return;
        }
        const posXEnd = touchEvent.changedTouches[0].clientX;
        if (posXStart < posXEnd) {
          this.$router.replace({ path: '/c/' + this.comic + '/' + this.getPrev() });
        } else if (posXStart > posXEnd) {
          this.$router.replace({ path: '/c/' + this.comic + '/' + this.getNext() });
        }
    },

  },
  created: function() {
    this.getJson();
  },
  watch: {
    '$route': 'getJson'
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  margin: 0;
  border: 0;
}
a {
  color: #42b983;
}
</style>
