<template>
  <div class="home">
    <h1>{{$route.params.comic}}</h1>
    <ComicComponent :nr="current" :comic="$route.params.comic" :key="current" />
  </div>
  <div>
    <input type="text" v-model="current" placeholder="edit me">
  </div>
</template>

<script>
// @ is an alias to /src
import ComicComponent from '../components/ComicComponent.vue'
import {comicCookies} from '../cookies.js'
//import Vue from 'vue';

export default {
  name: 'ComicView',
  components: { 
      ComicComponent
  },
  data() {
    return {
       comics: {},
       first: 1,
       current: 1,
       updates: 0,
    }
  },
  methods: {
    getComics() {
      fetch('/api/index.php?getComics')
        .then(response => {return response.json();})
        .then(data => { 
            this.comics = data;
            this.first = data[this.$route.params.comic]; 
            //this.current = this.first; 
            //this.updates++; 
            return data;
        })
    },
  },
  created: function() {
    this.getComics();
    var cookie = "";
    cookie = comicCookies.getCookie(this.$route.params.comic);
    console.log("cookie in ComicView")
    console.log("Cookie found: '" + cookie +"'");
    if ( cookie == "" ) {
      console.log("Cookie empty");
      comicCookies.setCookie(this.$route.params.comic,this.first, 365);
    } else {
      console.log("Cookie present");
      this.current = cookie;
      this.updates++;
    }
  },
  watch: {
    '$route': 'created'
  }
}
</script>
